@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.panoramaModal {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: $clr-light-grey;
  @include vp--740 {
    height: 100vh;
  }

  &:before {
    display: block;
    content: '';
    width: 100%;
    height: size-rem(300px);
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 99;
    pointer-events: none;

    @include vp--tablet {
      height: size-rem(300px, false, false, true);
    }

    @include vp--740 {
      height: size-rem(200px, true);
    }
  }
}

.panoramaModalWrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.panoramaModalContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  margin: 0;
  opacity: 1;
  transition: all 1s ease;
  cursor: grab;
  z-index: 10;

  div span {
    opacity: 0;
  }
}

.panoramaModalContainer_two {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  margin: 0;
  opacity: 0;
  cursor: grab;
  transition: all 1s ease;
  z-index: 0;

  div span {
    opacity: 0;
  }
}

.panoramaModalContainer_three {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  margin: 0;
  opacity: 0;
  cursor: grab;
  transition: all 1s ease;
  z-index: 0;

  div span {
    opacity: 0;
  }
}

.panoramaModalClose {
  position: absolute;
  top: size-rem(20px);
  right: size-rem(20px);
  width: size-rem(200px);
  height: auto;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  @include vp--740 {
    top: size-rem(20px, true);
    right: size-rem(20px, true);
    width: size-rem(200px, true);
  }
}

.panoramaModalBtnClose {
  top: size-rem(20px);
  right: size-rem(20px);
  z-index: 9999;

  @include vp--740 {
    top: size-rem(20px, true);
    right: size-rem(20px, true);
  }

  @include vp--tablet {
    top: size-rem(20px);
    right: size-rem(20px);
    width: size-rem(60px) !important;
    height: size-rem(60px) !important;

    svg {
      width: size-rem(20px) !important;
      height: size-rem(20px) !important;
    }
  }
}

.panoramaModalBtnCloseText {
  top: size-rem(30px);
  right: size-rem(100px);
  margin-right: size-rem(10px);
  z-index: 9999;
  font-family: $main-font-family;
  color: $clr-white;
  font-weight: 350;
  font-size: size-rem(18px);
  line-height: 140%;

  @include vp--740 {
    margin-right: size-rem(10px, true);
    top: size-rem(40px, true);
    right: size-rem(90px, true);
    font-size: size-rem(16px, true);
    line-height: 120%;
  }
}

.panoramaMinBack {
  position: absolute;
  top: 75vh;
  left: 0%;
  width: 100%;
  height: 25vh;
  z-index: 11;

  @include vp--740 {
    left: 0%;
    top: initial;
    bottom: 0vh;
    width: 100%;
    height: 25vh;
  }
}

.panoramaModalBtnsBox {
  position: absolute;
  top: 90vh;
  left: 42%;
  @include flex-row_sb;

  @include vp--740 {
    left: 18%;
    top: initial;
    bottom: 13vh;
  }

  @include vp--tablet {
    top: initial;
    bottom: 10vh;
    left: 38%;
  }
}

.panoramaModalBtn {
  background: transparent;
  color: $clr-white;
  border: none;

  margin-right: size-rem(25px);
  text-transform: uppercase;
  min-width: size-rem(80px);
  height: size-rem(48px);
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(13px);
  line-height: 120%;
  z-index: 80;
  cursor: pointer;

  @include vp--740 {
    min-width: size-rem(80px, true);
    height: size-rem(48px, true);
    margin-right: size-rem(15px, true);

    font-size: size-rem(13px, true);
  }

  @include vp--tablet {
    min-width: size-rem(130px);
    height: size-rem(70px);
    margin-right: size-rem(20px);
    font-size: size-rem(22px);
  }
}

.__active {
  border: 1px solid $clr-white;
  border-radius: size-rem(37px);

  @include vp--740 {
    border-radius: size-rem(37px, true);
  }
}

.panoramaModalPlug {
  color: $clr-white;
  background-color: $clr-black;
  font-size: size-rem(25px);
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  p {
    font-size: size-rem(25px);

    @include vp--740 {
      font-size: size-rem(25px, true);
    }
  }
}

.compass {
  position: absolute;
  top: size-rem(40px);
  left: size-rem(40px);
  z-index: 9999;

  @include vp--tablet {
    top: size-rem(50px, false, false, true);
    left: size-rem(50px, false, false, true);
  }

  @include vp--740 {
    top: size-rem(30px, true);
    left: size-rem(30px, true);
  }
}
