@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.formModal {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  height: 100svh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;

  @include vp--740 {
    z-index: 99999;
  }

  &__box {
    @include flex-column_sb;
    z-index: 4;
  }

  &__top {
    @include flex-row_sb;
    column-gap: size-rem(30px);
  }

  &__routes__btn {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  &__route__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__links {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: size-rem(88px);
    padding-top: size-rem(64px);
    padding-bottom: size-rem(80px);
  }

  &__link {
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    color: $clr-dark-black;
    font-size: size-rem(32px);
    line-height: 110%;
    width: size-rem(481px);
  }

  &__svgClass {
    position: absolute;
    width: size-rem(900px);
    width: size-rem(1050px);
    height: size-rem(980px);
    z-index: 1;
    right: size-rem(0px);
    top: size-rem(0px);
  }
}

.formModalBack {
  position: absolute;
  background: #1c1c1c;
  opacity: 0.2;
  width: 100%;
  height: 100%;
}

.formModalContainer {
  position: absolute;
  margin: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: size-rem(1217px);
  height: 100%;
  overflow: hidden;
  padding-top: size-rem(42px);
  padding-left: size-rem(80px);
  padding-right: size-rem(80px);
  background-color: $clr-white;
  box-sizing: border-box;

  @include vp--740 {
    width: 100%;
    left: -0.5%;
    height: 90vh;
  }
}

.formModalBtnClose {
  z-index: 9999;
  background: none !important;
}

.formModalTitle {
  margin-top: 10% !important;
  margin-bottom: size-rem(35px) !important;
  @include vp--740 {
    margin-top: 18% !important;
    margin-bottom: size-rem(35px, true) !important;
  }
}

.formModalTitle p {
  font-weight: 300 !important;
  font-size: size-rem(75px) !important;
  @include vp--740 {
    font-size: size-rem(42px, true) !important;
  }
}

.scrollableContent {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  height: 100vh;
  @include custom-scrollbar();
  padding-top: size-rem(40px);
  margin-top: size-rem(40px);
}

.gradient {
  width: 100%;
  width: size-rem(1100px);
  height: size-rem(80px);
  position: fixed;
  bottom: 0;
  left: 0;
  background: linear-gradient($clr-white, transparent);
  background-position: top;
  background-repeat: no-repeat;
  z-index: 10;
  rotate: 180deg;
}
