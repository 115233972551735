@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';
@import '../../assets/scss/mixins/custom-scrollbar.scss';

.north {
  position: absolute;
  bottom: -20px;
  left: 51%;
  transform: translate(-50%);

  @include vp--tablet {
    bottom: size-rem(-25px, false, false, true);
  }

  @include vp--740 {
    bottom: size-rem(-20px, true);
  }
}

.south {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%);

  @include vp--tablet {
    top: size-rem(-25px, false, false, true);
  }

  @include vp--740 {
    top: size-rem(-20px, true);
  }
}

.east {
  position: absolute;
  bottom: 48%;
  left: -15px;

  @include vp--tablet {
    left: size-rem(-20px, false, false, true);
  }

  @include vp--740 {
    left: size-rem(-15px, true);
  }
}

.west {
  position: absolute;
  bottom: 45%;
  right: -15px;

  @include vp--tablet {
    right: size-rem(-20px, false, false, true);
  }

  @include vp--740 {
    right: size-rem(-15px, true);
  }
}
