@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-scrollbar.scss';

.container {
  box-sizing: border-box;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include vp--tablet3 {
    min-height: 100vh;
    grid-template-columns: 1fr;
    padding-top: size-rem(85px);
  }

  @include vp--740 {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: size-rem(85px, true);
  }
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: $main-font-family;
  padding-top: size-rem(150px);
  padding-bottom: size-rem(150px);

  @include vp--tablet3 {
    padding-top: size-rem(250px);
    padding-bottom: size-rem(250px);
  }

  @include vp--740 {
    padding-top: size-rem(48px, true);
    padding-bottom: size-rem(48px, true);
    flex-grow: 1;
  }
}

.title {
  font-size: size-rem(85px);
  line-height: 100%;
  margin: 0;

  @include vp--740 {
    font-size: size-rem(60px, true);
  }
}

.desc {
  font-size: size-rem(18px);
  margin-top: size-rem(28px);

  @include vp--740 {
    font-size: size-rem(16px, true);
    margin-top: size-rem(24px, true);
  }
}

.imgWrapper {
  height: 100%;
  position: relative;

  @include vp--740 {
    height: size-rem(317px, true);
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  border: 1px solid $clr-grey;
  padding: size-rem(10px);
  border-radius: size-rem(37px);
  width: size-rem(260px);
  height: size-rem(73px);
  box-sizing: border-box;
  @include flex-row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.5s ease;
  font-weight: 300;
  font-size: size-rem(16px);
  line-height: 100%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: $clr-black;
  text-decoration: none;
  margin-top: size-rem(50px);

  &:hover {
    background-color: $clr-black;
    color: $clr-white;
    transition: all 0.5s ease;
  }

  &:focus {
    background-color: $clr-dark-grey;
    color: $clr-white;
    transition: all 0.5s ease;
  }

  @include vp--740 {
    width: size-rem(215px, true);
    height: size-rem(50px, true);
    border-radius: size-rem(40px, true);
    padding: size-rem(6px, true);
    font-size: size-rem(16px, true);
    margin-top: size-rem(36px, true);
  }
}
