@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.footerContainer,
.footerContainer__landing {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-top: size-rem(51.5px);
  padding-bottom: size-rem(51.5px);
  background-color: $clr-black;
  color: $clr-white;
}

.footerContainer {
  @include vp--740 {
    height: size-rem(720px, true);
    height: size-rem(800px, true);
    padding-top: size-rem(31.5px, true);
  }
}

.footerContainer__landing {
  @include vp--740 {
    height: size-rem(700px, true);
    height: size-rem(800px, true);
  }
}

.footerWrapper {
  width: 100%;
  //gap: size-rem(198px);
  gap: size-rem(180px);

  @include flex-row_sb;
  margin-bottom: size-rem(50px);

  @include vp--740 {
    margin-bottom: size-rem(0px);
  }
}

.footerLeft {
  @include vp--740 {
    width: 100%;
  }
}

.footerRight {
  @include flex-row;
  justify-content: start;
  gap: size-rem(50px);
  z-index: 100;
}

.footerNavMenu {
  //width: size-rem(575px);
  min-height: size-rem(150px);
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  //gap: size-rem(16px);

  @include vp--740 {
    width: 100%;
    min-height: size-rem(150px, true);
    gap: size-rem(16px, true);
    margin-bottom: size-rem(30px, true);
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: size-rem(16px);

    @include vp--740 {
      gap: size-rem(16px, true);
    }
  }
}

.footerNavItem {
  text-decoration: none;
  color: $clr-white;
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(18px);
  line-height: 140%;
  letter-spacing: -0.02em;
  margin-right: size-rem(85px);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: size-rem(5px);
  }

  @include vp--740 {
    font-size: size-rem(16px, true);
  }
}

.footerLogo,
.svgClass {
  display: block;
  width: size-rem(223px);
  height: size-rem(41px);
  margin-bottom: size-rem(70px);

  @include vp--740 {
    width: size-rem(172px, true);
    height: size-rem(32px, true);
    margin-bottom: size-rem(40px, true);
  }
}

.footerSubText {
  @include flex-row;
  color: $clr-white;
  //gap: size-rem(140px);
  align-items: center;
  white-space: nowrap;

  justify-content: space-between;
  @include vp--740 {
    @include flex-column_sb;
    align-items: flex-start;
    height: 22%;
    margin-top: 0;
    gap: size-rem(0px);
    white-space: normal;
  }

  &__left {
    display: flex;
    display: block;
    align-items: center;
    gap: size-rem(29px);

    @include vp--740 {
      order: 1;
      flex-direction: column-reverse !important;
      justify-content: space-between;
      gap: 0;
      width: 100%;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: size-rem(50px);
    @include vp--740 {
      flex-direction: column;
      align-items: flex-start;
      gap: size-rem(30px, true);
      margin-bottom: size-rem(10px, true);
    }
  }

  &__links {
    position: relative;
    z-index: 2;
    display: flex;
    gap: size-rem(50px);

    @include vp--740 {
      flex-direction: column;
      gap: size-rem(10px, true);
    }
  }

  &__link {
    font-family: $main-font-family;
    font-style: normal;
    font-weight: 350;
    color: $clr-white;
    font-size: size-rem(32px);
    line-height: 110%;

    @include vp--740 {
      font-size: size-rem(20px, true);
    }

    @include vp--740 {
      &:nth-child(1) {
        order: 1;
      }
    }
  }
}

.footerSubTextPreLink {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(14px);
  line-height: 120%;
  color: $clr-lightdark-grey;

  margin-bottom: size-rem(14px);
  white-space: nowrap;

  @include vp--740 {
    white-space: normal;
    font-size: size-rem(14px, true);
    margin-right: initial;
    text-underline-offset: size-rem(5px, true);
    order: 2;
    margin-bottom: 0;
    margin-top: size-rem(14px, true);
  }
}

.footerSecondSub {
  display: flex;
  gap: size-rem(29px);
  //justify-content: space-between;

  @include vp--740 {
    //flex-direction: row-reverse;
    flex-direction: column;
    order: 1;
  }
}

.footerSubTextLink {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(14px);
  line-height: 120%;
  color: $clr-lightdark-grey;
  text-decoration-line: underline !important;
  text-underline-offset: size-rem(5px);

  &__notline {
    text-decoration-line: none !important;
    //margin-left: size-rem(20px);

    @include vp--740 {
      color: $clr-white;
      margin-left: initial;
    }
  }

  @include vp--740 {
    font-size: size-rem(14px, true);
    margin-right: initial;
    text-underline-offset: size-rem(5px, true);
  }
}

.footerHr {
  width: size-rem(1440px);
  height: 0px;
  margin-bottom: size-rem(50px);
  border: 1.5px solid #2d2d2d;
  margin-top: 0;

  @include vp--740 {
    border: 1px solid #2d2d2d;
    width: 100%;
    margin-bottom: size-rem(30px, true);
  }
}

.footerWhite {
  &__button {
    border: 1px solid $clr-grey;
    border-radius: size-rem(37px);
    width: size-rem(188px);
    height: size-rem(50px);

    @include vp--740 {
      width: 100%;
      height: size-rem(50px, true);
      border-radius: size-rem(37px, true);
      margin-bottom: size-rem(30px, true);
    }
  }
}

.formaLogo {
  width: size-rem(120px);
  height: size-rem(52px);
  margin-top: size-rem(8px);

  @include vp--740 {
    width: size-rem(120px, true);
    height: size-rem(52px, true);
    margin-top: size-rem(8px);
  }
}

.footerLogo {
  margin-bottom: size-rem(132px);
}

.formaLogoBox {
  display: block;
  width: size-rem(223px);
  height: size-rem(41px);
  margin-bottom: size-rem(32px);

  @include vp--740 {
    width: size-rem(172px, true);
    height: size-rem(32px, true);
    margin-bottom: size-rem(40px, true);
  }

  & span {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: size-rem(14px);
    line-height: 120%;
    color: $clr-lightdark-grey;

    white-space: nowrap;

    @include vp--740 {
      white-space: normal;
      font-size: size-rem(14px, true);
      margin-right: initial;
      text-underline-offset: size-rem(5px, true);
      order: 2;
      margin-bottom: 0;
      margin-top: size-rem(14px, true);
    }
  }
}
