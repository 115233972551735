@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/responsive.scss';

.buttonCall {
  position: relative;
  background: $clr-white;
  color: $clr-black;
  border: none;
  border-radius: size-rem(37px);
  font-family: $primary-font-family;
  cursor: pointer;
  font-weight: 400;
  font-size: size-rem(16px);
  line-height: 140%;
  letter-spacing: -0.01em;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 25%;
    width: 50%;
    height: 0;
    background: $clr-black;
    transition: all 0.5s ease;
    z-index: 0;
  }
  &:hover:after {
    height: 100%;
    top: 0%;
    left: 0%;
    width: 100%;
    border-radius: size-rem(50px);
    z-index: 1;
    color: $clr-white;
  }

  &:hover span {
    color: $clr-white;
    z-index: 999;
    position: absolute;
    top: 26%;
    width: 100%;
    left: 0;
    transition: all 0.5s ease;

    @include vp--740 {
      top: 23%;
    }
  }

  @include vp--740 {
    font-size: size-rem(16px, true);
    border-radius: size-rem(37px, true);
    transition: all 0.5s ease;

    &:hover:after {
      height: 0%;
    }
    &:hover {
      background: $clr-black;
      color: $clr-white;
      transition: all 0.5s ease;
    }
  }
}
